<template>
    <div>
    <br>
        <v-container>
            <v-row justify="center">
            <v-img src="./../assets/three.png" alt="three"
            max-width="80%"></v-img>
            </v-row>
             <br><br>
             <p class="text-center intro-text"> {{ mo }} </p> 
             <p class="text-center sub-text">สาเหตุเกิดจาก เชื้อไวรัส Chili Veinal Mottle Virus (CVMV) <br>
				Genus : potyvirus<br>
            </p>
         <div>
            <v-container>
        <v-row justify="center">
            <v-col lg="6">
                <v-img src="./../assets/mottle.jpg" alt="mottle"
                max-width="100%"></v-img> <br>
                <p subtitle-1 class="text-center">อ้างอิงรูปภาพ: คู่มือศัตรูพริก</p>
            </v-col>
        </v-row>
            </v-container>
        </div>
        <br>
         <v-card 
            elevation="2"
            title
            dark
        > 
        <v-card-title>
             ลักษณะอาการ
        </v-card-title>
        </v-card> 
      
        <v-card 
        elevation="2"
        title
    > 
    <v-card-text
        class="font-weight-black"
    >
         {{ tt }}
    </v-card-text>
     </v-card> <br>

        <v-card 
        elevation="2"
        tile
        dark
        >
        <v-card-title 
        >
            วิธีการป้องกันและกำจัด  
        </v-card-title>
        </v-card>

        <v-card 
        elevation="2"
        title
        > 
        <v-card-text
        class="font-weight-black"
        >
            {{ le }}
        </v-card-text>
    
        </v-card>
        </v-container>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    name: 'Mottle',
    data() {
        return {
            mo:[],
            tt:[],
            le:[],
        }
    },
    mounted() {
         axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/4')
        .then((resp) => {
        this.mo = resp.data.data.d_name
    })
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/4')
        .then((resp) => {
        this.tt = resp.data.data.d_details
    })
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/4')
        .then((resp) => {
        this.le = resp.data.data.d_protect
    })
    }
}
</script>


<style scoped>
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}
</style>