<template>
     <v-container>
            <p class="intro-text text-center">การใช้งานเว็บแอปพลิเคชันวินิจฉัยโรคของใบพริก และวิธีการป้องกันเบื้องต้น</p>
           <p class=" text-center">ก่อนจะทำการวินิจฉัยโรคของใบพริก ให้ผู้ใช้งานทำการสังเกตุอาการของใบพริกที่ตนเองได้ปลูกไว้ก่อน
                    และทำตามขั้นตอน ดังนี้</p>
                    <v-row>

            <v-col lg="4">
                    <v-img src="./../assets/web1.png" alt="" max-width="50%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-1</v-icon>เลือกลักษณะใบพริกตามหัวข้อต่างๆ ให้ครบทุกช่อง</p> 
             </v-col>     
            
             <v-col lg="4">
                    <v-img src="./../assets/web2.png" alt="" max-width="62%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-2</v-icon>หลังจากเลือกเสร็จแล้วให้กดปุ่มวินิจฉัยโรค</p> 
             </v-col>     

              <v-col lg="4">
                    <v-img src="./../assets/web3.png" alt="" max-width="50%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-3</v-icon>จะมีหน้าแสดงผลข้อมูลของโรค และวิธีการป้องกัน</p> 
             </v-col>
                    </v-row>
                    <br>
             <p class="intro-text text-center">ตัวอย่างภาพประกอบสำหรับการเลือกอาการของใบพริกแต่ละหัวข้อ</p>
             <v-img  src="./../assets/web5.png" alt="" class="mx-auto" max-width="60%"></v-img>
             <br>
            <v-card
    class="mx-auto"
    max-width="680"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          Download
        </div>
        <v-list-item-title class="text-h5 mb-1">
          คู่มือการใช้งาน
        </v-list-item-title>
        <v-list-item-subtitle>อัพเดต : 26 เมษายน 2565</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-avatar
        tile
        size="80"
        color="blue"
      ></v-list-item-avatar>
    </v-list-item>

    <div>
        <v-container>
           <a href="https://drive.google.com/file/d/1S_H_i8JTWJcZXVp5xd82dpko219_dwfe/view?usp=sharing" target="_blank">Download</a>
        </v-container>
    </div>
        </v-card>
     </v-container>
</template>

<script>


export default {

    name: 'abouts',

}

</script>

<style scoped>

p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #1cc8a0 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}
</style>