<template>
    <div>
    <br>
        <v-container>
            <v-row justify="center">
            <v-img src="./../assets/six.png" alt="six"
            max-width="80%"></v-img>
            </v-row>
             <br><br>

             <p class="text-center intro-text"> {{ bac }} </p> 
             <p class="text-center sub-text">สาเหตุเกิดจาก เชื้อแบคทีเรีย Xanthomonas vesiactoria (ex Doidge Vauterin etrl)
            </p>
                
                <div>
            <v-container>
        <v-row justify="center">
            <v-col lg="6">
                <v-img src="./../assets/bacterials.jpeg" alt="bacterials"
                max-width="100%"></v-img><br>
                <p subtitle-1 class="text-center">อ้างอิงรูปภาพ: Kaset Go</p>
            </v-col>
        </v-row>
            </v-container>
        </div>
     
         <v-card 
            elevation="2"
            title
            dark
        > 
        <v-card-title>
             ลักษณะอาการ
        </v-card-title>
        </v-card> 
      
        <v-card 
        elevation="2"
        title
    > 
    <v-card-text
        class="font-weight-black"
    >
         {{ te }}
    </v-card-text>
     </v-card> <br>

        <v-card 
        elevation="2"
        tile
        dark
        >
        <v-card-title 
        >
            วิธีการป้องกันและกำจัด  
        </v-card-title>
        </v-card>

        <v-card 
        elevation="2"
        title
        > 
        <v-card-text
        class="font-weight-black"
        >
            {{ rial }}
        </v-card-text>
    
        </v-card>
        
        </v-container>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    name: 'Bacterial',
    
    data() {
        return {
            bac:[],
            te:[],
            rial:[]
        }
    },
    mounted() {
         axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/6')
        .then((resp) => {
        this.bac = resp.data.data.d_name})
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/6')
        .then((resp) => {
        this.te = resp.data.data.d_details})
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/6')
        .then((resp) => {
        this.rial = resp.data.data.d_protect})
    }
}
</script>


<style scoped>
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}
</style>