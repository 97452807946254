<template>
    <v-container class="about">
      <br>
        <Abouts/>
       
        
    </v-container>
</template>

<script>

import Abouts from '../components/Abouts.vue';

export default {
    name: 'About',
     components: {
        Abouts
     }
}

</script>

<style scoped>
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
 }
.image {
    margin: auto;
 
}
</style>