<template>
<div class="disease">
    <v-container>
      <br>
        <v-row justify="center">
      <Diagnose/>
        </v-row>
    </v-container>
</div>
</template>

<script>

  import Diagnose from '../components/Diagnose.vue';

  export default {
    name: 'Disease',
    components: {
        Diagnose
    }
    
  }
</script>

<style>

</style>
