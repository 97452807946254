<template>

      <v-img src="./../assets/banner.png" alt="" max-width="100%">
      </v-img>




</template>

<script>


export default {
  
}
</script>

<style lang="scss" scoped>

  h1.intro-text {
    font-size: 40px;
    font-weight: 800;
    font-family: cursive;
    color: #131313ba;
}
  p.description {
    font-size: 19px;
    font-family: cursive;
    margin: 18px 0px;
    color: #444444ba;
  }

a.btn{
      font-size:  14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;
      color: #ffffff;

      list-style: none;
      text-decoration: none;
    }
</style>