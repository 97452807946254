<template>
        <v-container>
            <p class="intro-text text-center">การใช้งานเว็บแอปพลิเคชันวินิจฉัยโรคของใบพริก และวิธีการป้องกันเบื้องต้น</p>
           <p class=" text-center">ก่อนจะทำการวินิจฉัยโรคของใบพริก ให้ผู้ใช้งานทำการสังเกตุอาการของใบพริกที่ตนเองได้ปลูกไว้ก่อน
                    และทำตามขั้นตอน ดังนี้</p>
                    <v-row>

            <v-col lg="4">
                    <v-img src="./../assets/web1.png" alt="" max-width="50%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-1</v-icon>เลือกลักษณะใบพริกตามหัวข้อต่างๆ ให้ครบทุกช่อง</p> 
             </v-col>     
            
             <v-col lg="4">
                    <v-img src="./../assets/web2.png" alt="" max-width="62%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-2</v-icon>หลังจากเลือกเสร็จแล้วให้กดปุ่มวินิจฉัยโรค</p> 
             </v-col>     

              <v-col lg="4">
                    <v-img src="./../assets/web3.png" alt="" max-width="50%" class="mx-auto"></v-img>
                   <p class="text-center"><v-icon color="success">mdi-numeric-3</v-icon>จะมีหน้าแสดงผลข้อมูลของโรค และวิธีการป้องกัน</p> 
             </v-col>
                    </v-row>
                    <br>
             <p class="intro-text text-center">ตัวอย่างภาพประกอบสำหรับการเลือกอาการของใบพริกแต่ละหัวข้อ</p>
             <v-img  src="./../assets/web5.png" alt="" class="mx-auto" max-width="60%"></v-img>
             <br><br>
                
                 <!-- <v-tabs class="mx-auto"
                 center-active
                 centered
                 dark>
                     <v-tab @click="tabone = true; tabtwo = false;  tabthree = false">สอบถามข้อมูลเพิ่มเติม</v-tab>
                     <v-tab @click="tabtwo = true; tabone = false; tabthree = false">ข่าวสาร</v-tab>
                     <v-tab @click="tabthree = true; tabtwo = false; tabone = false">วิธีการปลูก</v-tab>
                 </v-tabs>
                 <div>
                     <Tabone v-if="tabone" />
                     <Tabtwo v-if="tabtwo" />
                     <Tabthree v-if="tabthree" />
                 </div> -->
             
             <p class="text-sub">ข่าวสารน่าสนใจ</p>
             <Tabtwo/><br>
             <Tabthree/><br>
             <p class="text-sub">สอบถามข้อมูลเพิ่มเติม</p>
             <Tabone/>
        </v-container>

</template>


<script>
import Tabone from '../components/Tabone.vue';
import Tabtwo from '../components/Tabtwo.vue';
import Tabthree from '../components/Tabthree.vue';

export default {
    name: "Details",
    data() {
        return {
            tabone: true,
            tabtwo: false,
            tabthree: false

        }
    },
    components: {
        Tabone,
        Tabtwo,
        Tabthree
    }  
}

</script>

<style scoped>

p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #1cc8a0 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}
a.btn{
      list-style: none;
      text-decoration: none;
      color: #ffffff;
    }
button {
    font-size: 15px;
}
.basil {
  background-color: #FFFBE6 !important;
}
p.text-sub {
  font-size: 20px;
  font-weight: bolder;
  color: #102b3e !important;
}

</style>

