<template>
    <div>
    <br>
        <v-container>
            <v-row justify="center">
            
            <v-img src="./../assets/one.png" alt="one"
            max-width="80%"></v-img>
            </v-row>

             <br><br>
             <p class="text-center intro-text"> {{ result }} </p> 
             <p class="text-center sub-text">สาเหตุเกิดจาก เชื้อไวรัส 
		Pepper yellow left curl virus (PeYLCV) <br>
		Tomato yellow leaf curl virus (TYLCV) <br>
		Genus : Begomovirus
            </p>
        <div>
            <v-container>
        <v-row justify="center">
            <v-col lg="6">
                <v-img src="./../assets/yellows.jpg" class="mx-auto" alt="yellows"
                max-width="80%"
          ></v-img>
            </v-col>
            <v-col lg="6">
                <v-img src="./../assets/yellow.jpg" class="mx-auto" alt="yellow"
                max-width="80%"
          ></v-img>
            </v-col>
            <p subtitle-1 class="text-center">อ้างอิงรูปภาพ: ศูนย์ติดตามและแก้ไขปัญหาภัยพิบัติด้านการเกษตร กระทรวงเกษตรและสหกรณ์</p>
        </v-row>
            </v-container>
        </div>
       
         <v-card 
            elevation="2"
            title
            dark
        > 
        <v-card-title>
             ลักษณะอาการ
        </v-card-title>
        </v-card> 

        <v-card 
        elevation="2"
        title
    > 
    <v-card-text
        class="font-weight-black"
    >
         {{ yellow }}
    </v-card-text>
     </v-card> 
            <br>
        <v-card 
        elevation="2"
        tile
        dark
        >
        <v-card-title 
        >
            วิธีการป้องกันและกำจัด  
        </v-card-title>
        </v-card>

        <v-card 
        elevation="2"
        title
        > 
        <v-card-text
        class="font-weight-black"
        >
            {{ p }}
        </v-card-text>
        </v-card>
    </v-container>
</div>
</template>

<script>

import axios from "axios";

export default {
    name: 'Yellowdisease',
    
    data() {
        return {
        yellow: [],
        result: [],
        p:[],
        }
       
    },
     mounted() {
      axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/1')
      .then((resp) => {
        this.yellow = resp.data.data.d_details
        // console.log(resp.data.data)
      })
      axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/1')
      .then((resp) => {
        this.result = resp.data.data.d_name
        // console.log(resp.data.data.d_name)
      })
      axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/1')
      .then((resp) => {
        this.p = resp.data.data.d_protect
        // console.log(resp.data.data.d_name)
      })
}
}
</script>


<style scoped>
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}

</style>