<template>
    <div>
    <br>
        <v-container>
            <v-row justify="center">
            <v-img src="./../assets/five.png" alt="five"
            max-width="80%"></v-img>
            </v-row>

             <br><br>

             <p class="text-center intro-text"> {{ ring }} </p> 
             <p class="text-center sub-text">สาเหตุเกิดจาก เชื้อไวรัส Capsicum chlorosis virus, CaCV 
             <br> Watermelon silver mottle virus (WSMoV) 
             <br> Melon yellow spot virus (MYSV)
             <br> Tomato necrotic ringspot virus (TNRV)
             <br> Genus : Tospovirus
            </p>
                <br>

                 <div>
            <v-container>
        <v-row justify="center">
            <v-col lg="6">
                <v-img src="./../assets/ringspot.png" class="mx-auto" alt="ringspot"
                max-width="80%"></v-img> <br>
                <p subtitle-1 class="text-center">อ้างอิงรูปภาพ: กรมวิชาการเกษตร</p>
            </v-col>
        </v-row>
            </v-container>
        </div>
        <br>
         <v-card 
            elevation="2"
            title
            dark
        > 
        <v-card-title>
             ลักษณะอาการ
        </v-card-title>
        </v-card> 
      
        <v-card 
        elevation="2"
        title
    > 
    <v-card-text
        class="font-weight-black"
    >
         {{ sp }}
    </v-card-text>
     </v-card> <br>

        <v-card 
        elevation="2"
        tile
        dark
        >
        <v-card-title 
        >
            วิธีการป้องกันและกำจัด  
        </v-card-title>
        </v-card>

        <v-card 
        elevation="2"
        title
        > 
        <v-card-text
        class="font-weight-black"
        >
            {{ ot }}
        </v-card-text>
    
        </v-card>
        </v-container>
    </div>
</template>

<script>

import axios from 'axios';
export default {
    name: 'Ringspot',
    
    data() {
        return {
            ring:[],
            sp:[],
            ot:[],
        }
            
    },

    mounted() {
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/5')
        .then((resp) => {
        this.ring = resp.data.data.d_name
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/5')
        .then((resp) => {
        this.sp = resp.data.data.d_details
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/disease/name/5')
        .then((resp) => {
        this.ot = resp.data.data.d_protect  
        
    })
    }
}
</script>


<style scoped>
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
}
p.sub-text {
    font-size: 15px;
    font-weight: bolder;
    color: #333333ba;
}
</style>