<template>
     <v-container>
        <v-row>
        <v-col lg="3">
        <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/bullet-news-today/article_86438" target="_blank">
    <v-img
      src="./../assets/jk.jpg" alt="" 
      height="140px"
    ></v-img>
    </a>
    <v-card-title>
      “พริกรังสิมา” เพิ่มผลผลิต ทนต่อโรค พืชทางเลือกของการปลูกพริกเชิงการค้า
    </v-card-title>
    <v-card-subtitle>ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 28 พฤศจิกายน พ.ศ.2561</v-card-subtitle>
    </v-card>
        </v-col>

        <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/agricultural-technology/article_112445" target="_blank">
    <v-img
      src="./../assets/jkk.jpg" alt="" 
      height="140px"
    ></v-img>
    </a>
    <v-card-title>เรื่องง่ายๆ กับการปลูกพริกกินเองในบ้าน หรือในพื้นที่<br>จำกัด</v-card-title>
    <v-card-subtitle> ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 3 ตุลาคม พ.ศ.2564</v-card-subtitle>
    </v-card>
        </v-col>
        <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/highlight-clip/article_198833" target="_blank">
    <v-img
      src="./../assets/Screen.png" alt="" 
      height="140px"
    ></v-img>
    </a>
    <v-card-title>เคล็ดลับปลูกพริกให้ได้ผล<br>ผลิตดี เม็ดใหญ่ ผิวมันสวย<br>เป็นที่ต้องการของตลาด</v-card-title>
    <v-card-subtitle>ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 5 พฤศจิกายน พ.ศ.2564</v-card-subtitle>
    </v-card>
        </v-col>
         <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/bullet-news-today/article_199072" target="_blank">
    <v-img
      src="./../assets/jkkk.png" alt="" 
      height="100%"
      
    ></v-img>
    </a>
    <v-card-title>
      ชาวนาพิจิตร ปลูกพืชอายุ<br>สั้นส่งโรงงาน เสริมรายได้ ปลดหนี้ มีเงินเก็บออม
    </v-card-title>
    <v-card-subtitle>ที่มา : เกษตรกู้ชาติ <br>เผยแพร่ : 8 พฤศจิกายน พ.ศ.2564</v-card-subtitle>
    </v-card>
        </v-col>
       
        </v-row>
    </v-container>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>