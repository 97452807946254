<template>
  <v-app class="app">
    <v-content>
        <Header/>
    </v-content>
  <router-view></router-view>
  <br>
  
   <v-footer 
  padless
  class="bg-dark"
  >
  <v-container>
    <v-col
      class="text-center text-white bg-dark"
    >
     <br>
     <p>2022 - Copyright © มหาวิทยาลัยเทคโนโลยีราชมงคลล้านนา พิษณุโลก</p>
    </v-col>
  </v-container>
  </v-footer>
  
 
  </v-app>
</template>

<script>

import Header from './components/Header.vue';
export default {
  name: 'App',
  components: {
    Header,
  },

  data: () => ({
    
    }
  ),
};
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');
#app {
  font-family: 'Prompt', sans-serif;
  /* background-color: #FFFBE6 !important; */
  
}

</style>