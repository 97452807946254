<template>
    <div class="diagnose">
     <v-container fluid>
          <v-img src="./../assets/Logoweb.png" alt="" class="mx-auto" max-width="150px" ></v-img>
      </v-container>
     
     <p class="text-sub text-center">เลือกอาการบนใบพริก</p>
 <br>
 <v-container>
   <v-row align="center">
    <v-col cols="4">
      <v-card 
      class="pa-4"
      >
        <div class="text-center">
            <p class="intro-text">ลักษณะใบ</p>
            <v-img src="./../assets/leaf.png" alt="" class="mx-auto" max-width="100"></v-img>
        </div>
            <v-form>
              <v-card-text> 
                  <v-select 
                    v-model="mlselect"
                    :items="moreleaf"
                    label="ลักษณะใบ"
                    dense
                    color="red darken-4"
                  ></v-select>
              </v-card-text>
            </v-form>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card 
      class="pa-4"
      >
        <div class="text-center">
            <p class="intro-text">สีของใบ</p>
            <v-img src="./../assets/leaff.png" alt="" class="mx-auto" max-width="100"></v-img>
        </div>
            <v-form>
              <v-card-text> 
                  <v-select
                    v-model="lfselect"
                    :items="leafcolor"
                    label="สีของใบ"
                    dense
                    color="red darken-4"
                  ></v-select>
              </v-card-text>
            </v-form>
      </v-card>
    </v-col>

    <v-col cols="4">
      <v-card 
      class="pa-4"
      >
        <div class="text-center">
            <p class="intro-text">ลักษณะสีบนใบ</p>
            <v-img src="./../assets/leafss.png" alt="" class="mx-auto" max-width="100"></v-img>
        </div>
            <v-form>
              <v-card-text> 
                  <v-select
                    v-model="mcselect"
                    :items="morecolor"
                    label="ลักษณะสีบนใบ"
                    dense
                    color="red darken-4"
                  ></v-select>
              </v-card-text>
            </v-form>
      </v-card>
    </v-col>

    <v-col cols="4" class="mx-auto">
      <v-card 
      class="pa-4"
      >
        <div class="text-center">
            <p class="intro-text">แผลบนใบ</p>
             <v-img src="./../assets/leafs.png" alt="" class="mx-auto" max-width="120"></v-img>
        </div>
            <v-form>
              <v-card-text> 
                   <v-select
                    v-model="wolselect"
                    :items="woundonleaf"
                    label="แผลบนใบ"
                    dense
                    color="red darken-4"
                  ></v-select>
              </v-card-text>
            </v-form>
      </v-card>
    </v-col>

    <v-col cols="4" class="mx-auto">
      <v-card 
      class="pa-4"
      >
        <div class="text-center">
            <p class="intro-text">สีของแผล</p>
            <v-img src="./../assets/leafsss.png" alt="" class="mx-auto" max-width="130"></v-img>
        </div>
            <v-form>
              <v-card-text> 
                   <v-select
                    v-model="wcselect"
                    :items="woundcolor"
                    label="สีของแผล"
                    dense
                    color="red darken-4"
                    ></v-select>
              </v-card-text>
            </v-form>
      </v-card>
    </v-col>
   </v-row>
  </v-container>
  <br>
    <div class="text-center">
      <v-btn 
      rounded
      color="success"
      class="mr-4 mb5 " 
      @click="cal()"
    >
      วินิจฉัยโรค
      </v-btn>
    </div>
   
    </div>
</template>

<script>

import axios from 'axios';

export default {
    name: 'diagnose',

    data:() => ({
     
      mlselect: '',
      lfselect: '',
      wolselect: '',
      wcselect: '',
      mcselect: '',

      moreleaf: [],
      leafcolor:[],
      woundonleaf: [],
      woundcolor: [],
      morecolor: [],

     }),

     // เรียกข้อมูลมาแสดง select
     mounted() {
         axios.get('https://patcharaphonapi.aimaccount.com/api/moreleafs')
        .then((resp) => {
          for (const key in resp.data.data) {
            this.moreleaf.push(resp.data.data[key].ml_name)
          }
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/leafcolors')
        .then((resp) => {
          for (const key in resp.data.data) {
              this.leafcolor.push(resp.data.data[key].lf_name)
            }
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/woundonleafs')
        .then((resp) => {
          for (const key in resp.data.data) {
              this.woundonleaf.push(resp.data.data[key].wol_name)
            }
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/woundcolors')
        .then((resp) => {
          for (const key in resp.data.data) {
              this.woundcolor.push(resp.data.data[key].wc_name)
            }
        })
        axios.get('https://patcharaphonapi.aimaccount.com/api/morecolors')
        .then((resp) => {
          for (const key in resp.data.data) {
              this.morecolor.push(resp.data.data[key].mc_name)
            }
        })
    },
    //เช็คการเปลี่ยนแปลง

    //ถ้าเลือกไม่มีแผล จะกำหนดไม่มีสีด้วย
      watch: {
        wolselect(newSelect, oldSelect) {
          if (newSelect == "ไม่มีแผล"){
            this.wcselect = "ไม่มีสี";
          } else if (oldSelect === "ไม่มีแผล" && this.wcselect === "ไม่มีสี"){
            this.wcselect = "";
          }
        },
         // ถ้าเลือกสี จะกำหนดให้มีแผล
        wcselect(newSelect) {
          if (newSelect !== "ไม่มีสี") {
            this.wolselect = "มีแผล";
          } else {
            this.wolselect = "ไม่มีแผล";
      }
    },
  },
     methods: {
    cal() {
      if (this.mlselect && this.lfselect && this.wolselect && this.wcselect && this.mcselect) {
        console.log(this.mlselect);
        console.log(this.lfselect);
        console.log(this.wolselect);
        console.log(this.wcselect);
        console.log(this.mcselect);

        /** Code วิเคราะห์ */
        if (this.wolselect === "มีแผล") {
          if (this.wcselect === "เหลือง") {
            if (this.mcselect === "กระจายบนใบ") {
               this.$router.push('/bacterial');
            }
          } else if (this.wcselect === "น้ำตาล") {
            this.$router.push('/frogeye');
            //alert("โรคใบจุดตากบ");
          }
        }
        if (this.wolselect === "ไม่มีแผล") {
          if (this.mlselect === "ด่าง") {
            if (this.lfselect === "สีเหลืองกับสีเขียว") {
              this.$router.push('/yellowdisease');
              //alert("โรคใบหงิกเหลือง");
            } else if (this.lfselect === "สีเหลือง") {
              this.$router.push('/yellowdisease');
              //alert("โรคใบหงิกเหลือง");
            } else if (this.lfselect === "สีเขียวอ่อน") {
              this.$router.push('/mottle');
              //alert("โรคใบด่างประพริก");
            }
          } else if (this.mlselect === "ใบจุดสีขาว") {
              this.$router.push('/powdery');
              //alert("โรคราแป้งพริก");
          } else if (this.mlselect === "เบี้ยวและหงิกงอ") {
            if (this.lfselect === "สีเหลืองกับสีเขียว") {
              this.$router.push('/yellowdisease');
              //alert("โรคใบหงิกเหลือง");
            } else if (this.lfselect === "สีเหลือง") {
              this.$router.push('/yellowdisease');
              //alert("โรคใบหงิกเหลือง");
            } else if (this.lfselect === "สีเขียวอ่อน") {
              this.$router.push('/mottle');
              //alert("โรคใบด่างประพริก");
            }
          } else if (this.mlselect === "ไหม้") {
            this.$router.push('/ringspot');
            //alert("โรคไวรัสใบจุดวงแหวน");
          }
        }

        /** Code end */
      } else {
       
      alert("กรุณาเลือกข้อมูลให้ครบถ้วน");
      }
      },
      
      },
   
    

    };
</script>

<style lang="scss" scoped>
a.submit{
      font-size:  14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;
      color: #1b863f;
      list-style: none;
      text-decoration: none;
      
    }
p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #1cc8a0 !important;
}
p.text-sub {
  font-size: 20px;
  font-weight: bolder;
  color: #102b3e !important;
}

</style>