<template>
  <v-contant>
    <Template/>
    <br>
    <br>
    <Details/>
  </v-contant>
</template>

<script>
import Template from '../components/Template.vue';
import Details from '../components/Details.vue';

  export default {
    name: 'Home',
    components: {
      Template,
      Details
      
    }
    
  }
</script>

<style scoped>

p.intro-text {
    font-size: 20px;
    font-weight: bolder;
    color: #356859 !important;
 }
</style>
