<template>
  <v-container>
    <v-row>
    <v-col lg="6">
      <br> <a href="http://www.pmc09.doae.go.th/pmc092016/" target="_blank"> 
      <v-img src="./../assets/doae.jpg" alt="" max-width="100" class="mx-auto"> </v-img> </a> <br>
       <h4 class="text-center">ศูนย์ส่งเสริมเทคโนโลยีการเกษตรด้านอารักขาพืช จังหวัดพิษณุโลก</h4>
        <p class="text-center">ตั้งอยู่เลขที่  9  หมู่ที่  10  ตำบลอรัญญิก อำเภอเมือง จังหวัดพิษณุโลก 65000</p>
        <p class="text-center">โทรศัพท์/โทรสาร 055-253659, 093-1357670</p>
        <p class="text-center">E-mail : pmc09@doae.go.th,  pmc09.doae.@hotmail.com</p>
        <p class="text-center">Facebook : ศูนย์ส่งเสริมเทคโนโลยีการเกษตรด้านอารักขาพืชจังหวัดพิษณุโลก </p>
       
    </v-col>
    <v-col lg="6">
      <v-img src="./../assets/slide.jpg" alt="" height="200px"></v-img><br>
      <v-img src="./../assets/slidee.jpg" alt="" height="150px"></v-img>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>