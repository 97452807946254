<template>
    <v-container>
    <v-img src="./../assets/service.png" alt="" max-width="100%"></v-img> <br>
    <v-card 
            elevation="2"
            title
            dark
            max-width="652"
            class="mx-auto"
        > 
        <v-card-title>
            เจ้าหน้าที่
        </v-card-title>
        </v-card> 
    <v-card
    class="mx-auto"
    max-width="652"
  >
    <!-- <v-img src="./../assets/support.png" alt="" max-width="100" class="mx-auto"></v-img> -->
    
    <v-card-text class="text--primary">
        <div> <v-icon>account_circle</v-icon> ชื่อ : พัชรพร มานวม</div>
        <div> <v-icon>phone</v-icon> เบอร์โทรศัพท์ : 065-006-1065</div>
        <div> <v-icon>email</v-icon> อีเมล์ : patcharaphon.mn@gmail.com</div>
    </v-card-text>
  </v-card>
</v-container>

</template>

<script>
export default {
    name: 'Support',

}
</script>

<style scoped>
.card {
  background-color: #102b3e ;
}
</style>