<template>
    <v-container>
        <v-row>
        <v-col lg="3">
        <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/bullet-news-today/article_67846" target="_blank">
    <v-img
      src="./../assets/tach.jpg" alt="" 
      height="140px"
    ></v-img>
    </a>
    <v-card-title>
      เกษตรกรที่ตำบลพระแท่น<br>จังหวัดกาญจนบุรีปลูกพริก <br>กะเหรี่ยงและข้าวโพดหวาน
    </v-card-title>
    <v-card-subtitle>ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 10 มกราคม พ.ศ.2565</v-card-subtitle>
    </v-card>
        </v-col>

        <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/agricultural-technology/article_169524" target="_blank">
    <v-img
      src="./../assets/tech.png" alt="" 
      height="100%"
    ></v-img>
    </a>
    <v-card-title>เกษตรกรหญิงแกร่งเมือง<br>เจ้าพ่อพญาแลปลูกพริก<br>เหลืองอินโดขายมาเลเซีย</v-card-title>
    <v-card-subtitle> ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 18 มกราคม พ.ศ.2564 </v-card-subtitle>
    </v-card>
        </v-col>
        <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/agricultural-technology/article_178029" target="_blank">
    <v-img
      src="./../assets/techs.png" alt="" 
      height="100%"
    ></v-img>
    </a>
    <v-card-title> พริกขาวชัยบุรี พริกพื้น<br>เมืองพัทลุง การันตีคุณ<br>ภาพขายส่ง14จังหวัดภาคใต้</v-card-title>
    <v-card-subtitle>ที่มา : เทคโนโลยีการเกษตร <br>เผยแพร่ : 9 เมษายน พ.ศ.2564  </v-card-subtitle>
    </v-card>
        </v-col>
         <v-col lg="3">
     <v-card
    class=""
    max-width="300"
  >
    <a href="https://www.technologychaoban.com/bullet-news-today/article_142895" target="_blank">
    <v-img
      src="./../assets/techss.png" alt="" 
      height="100%"
      
    ></v-img>
    </a>
    <v-card-title>
      ดัน “พริกปลอดภัย” สู่เกษตรแปลงใหญ่ ถอดบทเรียนที่ปลูกน้อย-เหลือเงินมาก
    </v-card-title>
    <v-card-subtitle>ที่มา : เก็บมาเล่า <br>เผยแพร่ : 24 มีนาคม พ.ศ.2563  </v-card-subtitle>
    </v-card>
        </v-col>
       
        </v-row>
    </v-container>
    
</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>